import OrderDetail from '@/page/distributor/tour/back/component/orderDetail/1.0.5/index.vue'
import web_tourOrder_details from '@/lib/data-service/default/web_tourOrder_details'

export default {
    name: "TourFrontPay",
    data() {
        return {
            detailData: {}
        };
    },
    components: { OrderDetail },
    methods: {
        toSearch(query) {
            this.$router.push({
                name: 'distributor-tour-front-search',
                query
            })
        },
        toHome() {
            this.$router.push({
                name: 'distributor-tour-front-home'
            })
        },
        toDetail() {
            this.$router.push({
                name: 'distributor-tour-front-details',
                query: {
                    productNo: this.detailData.productNo
                }
            })
        },
        getDetail() {
            let orderId = this.$route.query.id
            web_tourOrder_details({ orderId: orderId }).then(res => {
                this.detailData = res.writeOrderResponse
            })
        }
    },
    created() {
        this.getDetail()
    },
    activated() { },
    watch: {},
    computed: {}
};
